var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-table-wrapper',_vm._g({staticClass:"customers-table",attrs:{"infinite-id":_vm.infiniteId,"list":_vm.list,"infinite-loading":_vm.infiniteLoading,"table-height":_vm.tableHeight,"with-select":_vm.isRoleAdmin},scopedSlots:_vm._u([{key:"customColumns",fn:function(){return [_c('el-table-column',{attrs:{"prop":"created_at","label":"Добавлен","sortable":"custom","class-name":"customers-table__cell--date","min-width":"124"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dateJoined = ref.row.dateJoined;
return [_vm._v(" "+_vm._s(_vm.formatDate(dateJoined))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"updated_at","label":"Обновлён","class-name":"owners-table__cell--date","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var updatedAt = ref.row.updatedAt;
return [_vm._v(" "+_vm._s(_vm.formatDate(updatedAt))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"status","sortable":"custom","label":"Статус","class-name":"customers-table__cell--status","min-width":"134"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var workflowStatus = ref.row.workflowStatus;
return [(workflowStatus)?_c('v-tag',{attrs:{"custom-color":workflowStatus.color}},[_vm._v(" "+_vm._s(workflowStatus.shortname || workflowStatus.name))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"name","sortable":"custom","label":"Имя покупателя","class-name":"customers-table__cell--name","min-width":"244","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var name = ref_row.name;
var id = ref_row.id;
return [(name && id)?_c('v-button',{staticClass:"customers-table__client-link",attrs:{"is-text":"","to":{ name: 'customer-edit', params: { customerId: id } }}},[_vm._v(_vm._s(name))]):[_vm._v(_vm._s(name || '-')+" ")]]}}])}),_c('el-table-column',{attrs:{"prop":"phone","label":"Телефон","class-name":"customers-table__cell--phone","min-width":"143"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var customerProfile = ref_row.customerProfile;
var phone = ref_row.phone;
return [_c('v-phones-popover',{attrs:{"phones":[phone]}})]}}])}),(_vm.isRoleAdmin)?_c('el-table-column',{attrs:{"prop":"agent","sortable":"custom","class-name":"customers-table__cell--agent","label":"Агент","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var agent = ref.row.agent;
return [(agent)?_c('v-agent',{attrs:{"photo":agent.photo},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(agent.name)+" ")]):_c('span',[_vm._v("-")])]}}],null,false,1957114441)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"adverts","label":"Объект","class-name":"customers-table__cell--advert","min-width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var adverts = ref.row.adverts;
return [_c('v-adverts-popover',{attrs:{"adverts":adverts}})]}}])}),_c('el-table-column',{attrs:{"prop":"advert_categories","label":"Категория","class-name":"customers-table__cell--adverts-category","width":"210"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var advertCategories = ref.row.advertCategories;
return [_vm._v(" "+_vm._s((advertCategories && _vm.formatAdvertCategories(advertCategories)) || '-')+" ")]}}])}),(_vm.isRoleAdmin)?_c('el-table-column',{attrs:{"prop":"call_source","label":"Источник","class-name":"customers-table__cell--call-source","min-width":"190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var callSource = ref.row.callSource;
return [_c('div',[_vm._v(_vm._s(callSource.name || '-'))])]}}],null,false,1211064719)}):_vm._e()]},proxy:true}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }