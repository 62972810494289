<template>
  <match-media v-slot="{ desktop, tablet, mobile }">
    <aside class="customers__filters-mobile">
      <v-drawer :visible.sync="filtersVisible">
        <filters-mobile
          :default-filters="queryParams"
          :count="params.count"
          @close="filtersVisible = false"
          @apply-filters="applyFilters"
        />
      </v-drawer>
    </aside>

    <aside v-if="!mobile" class="customers__bulk-edit">
      <v-drawer position="right" without-background :visible.sync="bulkEditVisible">
        <customer-bulk-edit-dialog
          :customers="selectedCustomersEdit"
          @close="bulkEditVisible = false"
          @archive="archiveCustomers"
          @activate="activateCustomers"
          @submitted="editCustomerSubmit"
        ></customer-bulk-edit-dialog>
      </v-drawer>
    </aside>

    <div class="customers">
      <div ref="customersTopWrapper" class="customers__top-wrapper">
        <div class="customers__header">
          <v-page-title class="customers__title">
            Покупатели
            <span class="customers__count">{{ params.count }}</span>
          </v-page-title>
          <v-suggest-search
            v-if="desktop"
            class="customers__searchrow"
            :min-number-length="$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS"
            :min-string-length="$options.MINIMUM_SEARCH_LENGTH_BY_STRING"
            :query-string="queryParams.phone || queryParams.query"
            :get-suggestions-method="getSearchSuggestionsList"
            @reset="resetSuggestions"
          >
            <template #searchResultItem="{item}">
              <customer-suggest-search-item
                :customer="item"
                @click="goToCustomerPage(item.id)"
              ></customer-suggest-search-item>
            </template>
          </v-suggest-search>
          <div class="customers__buttons">
            <v-button v-if="!mobile" class="customers__button customers__button--filters" @click="toggleFilters">
              <v-icon-filters class="customers__button-icon customers__button-icon--filters" />
              Фильтры
            </v-button>
          </div>
        </div>

        <div class="customers__tabs-wrapper">
          <v-tabs :value="queryParams.status" :primary="mobile" class="customers__tabs" @input="changeStatus">
            <v-tab v-for="(customerStatus, index) of $options.STATUSES_MAP" :key="index" :name="customerStatus.value">
              {{ customerStatus.label }}
            </v-tab>
          </v-tabs>
        </div>

        <v-button v-if="mobile" class="customers__button customers__button--filters" @click="toggleFilters">
          <v-icon-filters class="customers__button-icon customers__button-icon--filters" />
          Фильтры
        </v-button>

        <v-suggest-search
          v-if="!desktop"
          class="customers__search"
          :min-number-length="$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS"
          :min-string-length="$options.MINIMUM_SEARCH_LENGTH_BY_STRING"
          :query-string="queryParams.phone || queryParams.query"
          :get-suggestions-method="getSearchSuggestionsList"
          @reset="resetSuggestions"
        >
          <template #searchResultItem="{item}">
            <customer-suggest-search-item
              :customer="item"
              @click="goToCustomerPage(item.id)"
            ></customer-suggest-search-item>
          </template>
        </v-suggest-search>
      </div>
      <div class="customers__content">
        <template v-if="!mobile">
          <customers-table
            :table-height="tableHeight"
            :infinite-loading="infiniteLoading"
            :list="list"
            :infinite-id="infiniteId"
            @edit="editCustomer"
            @next-page-request="nextPageRequest"
            @change-sort="applyFilters"
            @bulk-edit="bulkEdit"
          ></customers-table>
        </template>
        <template v-else>
          <customer-list-item
            v-for="customer in list"
            :key="customer.id"
            v-bind="customer"
            @archive="archiveCustomers([customer.id])"
            @activate="activateCustomers([customer.id])"
            @pending-edit="editCustomer(customer)"
            @open="goToCustomerPage(customer.id)"
            @edit="editCustomerMobile(customer)"
          />
          <infinite-loading force-use-infinite-wrapper :identifier="infiniteId" @infinite="infiniteHandler">
            <template #no-more><span></span></template>
            <template #no-results><span></span></template>
          </infinite-loading>
        </template>
      </div>
      <customer-edit-dialog
        :visible.sync="editDialogVisible"
        :customer-data="customerData"
        @submitted="editCustomerSubmit"
      />
      <customer-edit-pending-dialog
        :visible.sync="editPendingDialogVisible"
        :draft-data="customerDraftData"
        :customer-data="customerData"
        @submitted="editCustomerSubmit"
      ></customer-edit-pending-dialog>
    </div>
  </match-media>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import VIconFilters from '@/components/icons/VFilters.vue'
import FiltersMobile from '@/components/Customer/Filters/Mobile.vue'
import CustomerListItem from '@/components/Customer/ListItem.vue'
import CustomersTable from '@/components/Customer/Table.vue'
import CustomerEditDialog from '@/components/Customer/EditDialog.vue'
import CustomerEditPendingDialog from '@/components/Customer/EditPendingDialog.vue'
import CustomerBulkEditDialog from '@/components/Customer/BulkEditDialog.vue'
import CustomerSuggestSearchItem from '@/components/Customer/SuggestSearchItem.vue'

import VPageTitle from '@/components/common/VPageTitle.vue'
import VButton from '@/components/common/VButton.vue'
import VDrawer from '@/components/common/VDrawer.vue'
import VSuggestSearch from '@/components/common/VSuggestSearch.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import noticeService from '@/services/notification'
import confirmService from '@/services/confirmation'
import { customersService } from '@/services/http'
import CancelSourceManager from '@/services/http/CancelSourceManager'
import { loadingService } from '@/services/loading'
// eslint-disable-next-line import/no-cycle
import redirectIfNetworkIssue from '@/router/utils'
import { CUSTOMER_STATUS_ACTIVE, CUSTOMER_STATUS_ARCHIVE } from '@/constants/statuses/customers'
import { MatchMedia } from 'vue-component-media-queries'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { pluralizeNumeral } from '@/utils/pluralization'
import { getCalculatingHeightTable } from '@/utils/calculatingHeightTable'

const MINIMUM_SEARCH_LENGTH_BY_NUMBERS = 7
const MINIMUM_SEARCH_LENGTH_BY_STRING = 3

const STATUSES_MAP = [
  { label: 'Актуальные', value: CUSTOMER_STATUS_ACTIVE },
  { label: 'Архив', value: CUSTOMER_STATUS_ARCHIVE }
]

export default {
  MINIMUM_SEARCH_LENGTH_BY_NUMBERS,
  MINIMUM_SEARCH_LENGTH_BY_STRING,
  STATUSES_MAP,
  name: 'Customers',
  components: {
    CustomerListItem,
    CustomerBulkEditDialog,
    CustomerEditPendingDialog,
    CustomerEditDialog,
    CustomerSuggestSearchItem,
    CustomersTable,
    InfiniteLoading,
    VSuggestSearch,
    VDrawer,
    VIconFilters,
    FiltersMobile,
    MatchMedia,
    VPageTitle,
    VButton,
    VTabs,
    VTab
  },
  data() {
    return {
      // eslint-disable-next-line vue/max-len
      infiniteId: +new Date(), // Рекомендованное значение по умолчанию +new Date() https://peachscript.github.io/vue-infinite-loading/api/#identifier
      infiniteLoading: false,
      tableHeight: 0,
      filtersVisible: false,
      loading: false,
      list: [],
      customerData: {},
      customerDraftData: {},
      customerStatuses: [],
      customerCallSources: [],
      params: {
        count: 0,
        offset: 0,
        limit: 10
      },
      editDialogVisible: false,
      editPendingDialogVisible: false,
      bulkEditVisible: false,
      selectedCustomersEdit: []
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    queryParams() {
      return {
        phone: this.$route.query.phone || undefined,
        query: this.$route.query.query || undefined,
        status: this.$route.query.status || CUSTOMER_STATUS_ACTIVE,
        categoryId: Number(this.$route.query.categoryId) || undefined,
        agentId: Number(this.$route.query.agentId) || undefined,
        priceLte: Number(this.$route.query.priceLte) || undefined,
        priceGte: Number(this.$route.query.priceGte) || undefined,
        dateFrom: this.$route.query.dateFrom || undefined,
        dateTo: this.$route.query.dateTo || undefined,
        lastReminderFrom: this.$route.query.lastReminderFrom || undefined,
        lastReminderTo: this.$route.query.lastReminderTo || undefined,
        workflowStatus: Number(this.$route.query.workflowStatus) || undefined,
        orderBy: this.$route.query.orderBy || '-created_at'
      }
    }
  },
  watch: {
    editPendingDialogVisible(val) {
      if (!val) {
        this.customerData = {}
        this.customerDraftData = {}
      }
    },
    editDialogVisible(val) {
      if (!val) this.customerData = {}
    },
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  mounted() {
    const { customersTopWrapper } = this.$refs
    this.tableHeight = getCalculatingHeightTable(customersTopWrapper)
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      const fetchResolvers = []
      if (!this.customerCallSources.length) {
        const customerCallSourcesRequest = this.fetchCustomerCallSources()
        fetchResolvers.push(customerCallSourcesRequest)
      }
      if (!this.customerStatuses.length) {
        const customerStatusesRequest = this.fetchCustomerStatuses()
        fetchResolvers.push(customerStatusesRequest)
      }
      if (fetchResolvers.length) {
        Promise.all(fetchResolvers).then(() => {
          this.resetCustomersList()
          this.fetchCustomers()
        })
      } else {
        this.resetCustomersList()
        this.fetchCustomers()
      }
    })
    next()
  },
  methods: {
    bulkEdit(customers) {
      if (customers.length) {
        this.selectedCustomersEdit = customers.map(customer => this.formattingEditCustomerData(customer))
        this.bulkEditVisible = true
      } else {
        this.selectedCustomersEdit = []
        this.bulkEditVisible = false
      }
    },
    infiniteHandler($state) {
      this.nextPageRequest($state)
    },
    resetCustomersList() {
      this.customerData = {}
      this.customerDraftData = {}
      this.params.offset = 0
      this.list = []
    },
    nextPageRequest(infinityState) {
      if (this.list.length) this.fetchCustomers({ infinityState })
    },
    formatCustomerList({ list, status = null }) {
      return list.map(customer => {
        const formattedCustomer = {
          ...customer,
          agent: customer.customerProfile?.agent || null,
          callSource: this.getCustomerCallSource(customer),
          workflowStatus: this.getCustomerStatus(customer)
        }
        return status ? { ...formattedCustomer, status } : formattedCustomer
      })
    },
    resetSuggestions() {
      this.updateQueryParams({ phone: undefined, query: undefined })
    },
    updateQueryParams(newParams) {
      this.$router.push({ query: { ...this.queryParams, ...newParams } })
    },
    toggleFilters() {
      this.filtersVisible = !this.filtersVisible
    },
    filtersSearch(filters) {
      this.updateQueryParams(filters)
    },
    applyFilters(filters) {
      this.filtersVisible = false
      this.updateQueryParams(filters)
    },
    changeStatus(status) {
      this.updateQueryParams({ status })
    },
    addCustomer() {
      this.editDialogVisible = true
    },
    editCustomerMobile(customer) {
      this.editDialogVisible = true
      this.customerData = this.formattingEditCustomerData(customer)
    },
    editCustomer(customer) {
      this.customerData = this.formattingEditCustomerData(customer)
      this.fetchCustomerDraft(customer.versionId).then(() => {
        this.editPendingDialogVisible = true
      })
    },
    fetchCustomerDraft(versionId) {
      return customersService.getCustomerDraft(versionId).then(draftData => {
        this.customerDraftData = this.formattingEditCustomerData(draftData)
      })
    },
    formattingEditCustomerData(data) {
      return {
        ...data,
        callSource: this.getCustomerCallSource(data),
        status: this.getCustomerStatus(data),
        agent: data.customerProfile.agent
      }
    },
    getCustomerCallSource({ customerProfile }) {
      if (this.customerCallSources.length) {
        if (customerProfile) {
          const { callSource: currentCallSources } = customerProfile
          if (currentCallSources) {
            const source = this.customerCallSources.find(
              callSource => callSource.id === (currentCallSources.id || currentCallSources)
            )
            if (source) return source
          }
        }
        return this.customerCallSources.find(callSource => callSource.name.toLowerCase() === 'не выбран')
      }
      return 'Не выбран'
    },
    getCustomerStatus({ customerProfile }) {
      if (this.customerStatuses.length && customerProfile) {
        const { status: currentStatus } = customerProfile
        if (currentStatus) {
          const statusObject = this.customerStatuses.find(status => status.id === (currentStatus.id || currentStatus))
          if (statusObject) return statusObject
        }
      }
      return ''
    },
    fetchCustomerStatuses() {
      this.loading = true
      return customersService
        .getCustomerStatusesMap()
        .then(statuses => {
          this.customerStatuses = statuses
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchCustomerCallSources() {
      this.loading = true
      return customersService
        .getCallSourcesMap()
        .then(callSources => {
          this.customerCallSources = callSources
        })
        .finally(() => {
          this.loading = false
        })
    },
    goToCustomerPage(customerId) {
      this.$router.push({
        name: 'customer-edit',
        params: { customerId }
      })
    },
    getSearchSuggestionsList({ inputValue, fetchType }) {
      this.updateQueryParams(
        fetchType === 'number' ? { phone: inputValue, query: undefined } : { phone: undefined, query: inputValue }
      )
      const runWithCancel = CancelSourceManager.withCancelSource('fetch-customers-suggest-search', true)
      return runWithCancel(({ cancelSource }) => {
        const searchParam = fetchType === 'number' ? { phone: inputValue } : { query: inputValue }
        return customersService.getList({ cancelToken: cancelSource.token, ...searchParam })
      }).then(({ results }) => {
        return this.formatCustomerList({ list: results })
      })
    },
    fetchCustomers({ infinityState, ...params } = {}) {
      const runWithCancel = CancelSourceManager.withCancelSource('fetch-customers', true)

      const fetchParams = { ...this.queryParams, ...params }
      const { limit, offset } = this.params

      runWithCancel(({ cancelSource }) => {
        if (!infinityState) this.loading = true
        else this.infiniteLoading = true
        return customersService.getList({ limit, offset, cancelToken: cancelSource.token, ...fetchParams })
      })
        .then(({ count, results }) => {
          this.params = { ...this.params, count }
          if (results.length) {
            this.list.push(...this.formatCustomerList({ list: results, status: this.queryParams.status }))
            this.params.offset += this.params.limit
            if (infinityState) {
              infinityState.loaded()
            } else {
              this.infiniteId += 1 // сброс скролла на текущее значение
            }
            return
          }
          if (infinityState) infinityState.complete()
        })
        .catch(redirectIfNetworkIssue)
        .finally(() => {
          this.loading = false
          this.infiniteLoading = false
        })
    },
    editCustomerSubmit() {
      this.resetCustomersList()
      this.fetchCustomers()
    },
    archiveCustomers(ids) {
      const customersGenitiveCase = pluralizeNumeral(ids.length, 'покупателя', 'покупателей', 'покупателей')
      const successText = pluralizeNumeral(
        ids.length,
        'Покупатель архивирован',
        'Покупатели архивированы',
        'Покупатели архивированы'
      )

      confirmService
        .ask({ title: `Вы точно хотите архивировать ${customersGenitiveCase}?` })
        .then(() => {
          this.loading = true
          customersService
            .bulkArchiveCustomers(ids)
            .then(() => {
              this.resetCustomersList()
              this.fetchCustomers()
              noticeService.success(successText)
            })
            .catch(() => noticeService.error(`Не удалось архивировать ${customersGenitiveCase}`))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    activateCustomers(ids) {
      const customersGenitiveCase = pluralizeNumeral(ids.length, 'покупателя', 'покупателей', 'покупателей')
      const successText = pluralizeNumeral(
        ids.length,
        'Покупатель активирован',
        'Покупатели активированы',
        'Покупатели активированы'
      )
      confirmService
        .ask({ title: `Вы точно хотите активировать ${customersGenitiveCase}?` })
        .then(() => {
          this.loading = true
          customersService
            .bulkActivateCustomers(ids)
            .then(() => {
              this.resetCustomersList()
              this.fetchCustomers()
              noticeService.success(successText)
            })
            .catch(() => noticeService.error(`Не удалось активировать ${customersGenitiveCase}`))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
