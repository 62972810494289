<template>
  <v-bulk-edit-dialog
    class="customer-bulk-edit-dialog"
    @close="close"
    @reset="updateCustomerEditModel(isSingleEdit ? customers[0] : {})"
  >
    <template #itemsCount>
      {{ customersCountText }}
    </template>
    <template #body>
      <v-form class="customer-bulk-edit-dialog__form" @submit.prevent="onSubmit">
        <template #default>
          <v-form-row>
            <v-form-field label="АГЕНТ">
              <v-select v-model="customerEditModel.agent" label="name" append-to-body :options="agentOptions" />
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="СТАТУС">
              <v-select v-model="customerEditModel.status" label="name" append-to-body :options="statusOptions" />
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ИСТОЧНИК ЛИДА">
              <v-select
                v-model="customerEditModel.callSource"
                label="name"
                append-to-body
                :options="callSourceOptions"
              />
            </v-form-field>
          </v-form-row>
        </template>
        <template #footer>
          <v-button class="customer-bulk-edit-dialog__form-action" @click="close">Отменить</v-button>
          <v-button class="customer-bulk-edit-dialog__form-action" primary type="submit">Обновить</v-button>
        </template>
      </v-form>
    </template>
    <template #footer>
      <v-button v-if="isCustomersActive" class="customer-bulk-edit-dialog__action" is-text @click="archive">{{
        `Архивировать ${customersCountActionText}`
      }}</v-button>
      <v-button v-else class="customer-bulk-edit-dialog__action" is-text @click="activate">{{
        `Активировать  ${customersCountActionText}`
      }}</v-button>
    </template>
  </v-bulk-edit-dialog>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import { agentsService, customersService } from '@/services/http'
import { pluralizeNumeral } from '@/utils/pluralization'
import { formatAgentObject } from '@/utils/formatters'
import VForm from '@/components/form/VForm.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VSelect from '@/components/common/VSelect.vue'
import { cloneDeep } from '@/utils/common'
import { CUSTOMER_STATUS_ACTIVE } from '@/constants/statuses/customers'
import VBulkEditDialog from '@/components/common/VBulkEditDialog.vue'

export default {
  name: 'CustomerBulkEditDialog',
  components: {
    VBulkEditDialog,
    VSelect,
    VFormRow,
    VFormField,
    VForm,
    VButton
  },
  props: {
    customers: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      errors: {},
      customerEditModel: {},
      agentOptions: [],
      statusOptions: [],
      callSourceOptions: []
    }
  },
  computed: {
    customersIds() {
      return this.customers.map(customer => customer.id)
    },
    isCustomersActive() {
      return this.$route.query.status === CUSTOMER_STATUS_ACTIVE
    },
    customersCount() {
      return this.customers.length
    },
    customersCountActionText() {
      const text = pluralizeNumeral(this.customersCount, 'покупателя', 'покупателей', 'покупателей')
      return `${this.customersCount} ${text}`
    },
    customersCountText() {
      const text = pluralizeNumeral(this.customersCount, 'покупатель', 'покупателя', 'покупателей')
      return `${this.customersCount} ${text}`
    },
    isSingleEdit() {
      return this.customersCount === 1
    }
  },
  watch: {
    customers: {
      immediate: true,
      handler(newCustomersArray) {
        if (this.isSingleEdit) {
          this.updateCustomerEditModel(newCustomersArray[0])
        } else {
          this.updateCustomerEditModel({})
        }
      }
    }
  },
  created() {
    this.selectAgents()
    this.selectStatuses()
    this.selectCallSources()
  },
  methods: {
    archive() {
      this.$emit('archive', this.customersIds)
    },
    activate() {
      this.$emit('activate', this.customersIds)
    },
    updateCustomerEditModel(model) {
      this.customerEditModel = cloneDeep(model)
    },
    close() {
      this.updateCustomerEditModel({})
      this.$emit('close')
    },
    onSubmit() {
      customersService.bulkUpdate(this.customersIds, this.customerEditModel).finally(() => {
        this.$emit('submitted')
        this.close()
      })
    },
    selectAgents() {
      this.agentOptionsLoading = true
      return agentsService
        .select()
        .then(agentList => {
          this.agentOptions = agentList.map(agent => formatAgentObject(agent))
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    selectStatuses() {
      return customersService.getCustomerStatusesMap().then(statusesList => {
        this.statusOptions = statusesList
      })
    },
    selectCallSources() {
      return customersService.getCallSourcesMap().then(callSourcesList => {
        this.callSourceOptions = callSourcesList
      })
    }
  }
}
</script>
