export const SORT_OPTIONS_MAP = [
  { label: 'Сначала новые', value: '-created_at' },
  { label: 'Сначала старые', value: 'created_at' },
  { label: 'от А до Я', value: 'name' },
  { label: 'от Я до А', value: '-name' },
  { label: 'Сначала без агентов', value: '-agent' },
  { label: 'Сначала с агентами', value: 'agent' },
  { label: 'Статус от А до Я', value: 'status' },
  { label: 'Статус от Я до А', value: '-status' },
  { label: 'Источник лида от А до Я', value: 'call_source' },
  { label: 'Источник лида от Я до А', value: '-call_source' }
]
