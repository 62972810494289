import { BLUE, GREEN, LIGHT_GRAY, PURPLE, RED } from '@/constants/tagColors'

const IN_WORK_STATUS_TEXT = 'В работе'
const WORK_PAUSED_STATUS_TEXT = 'На паузе'
const WORK_COMPLETE_STATUS_TEXT = 'Завершена'
const UPDATE_STATUS_TEXT = 'Уточнить'
const RETURN_TO_WORK_STATUS_TEXT = 'Вернуть'

const IN_WORK_STATUS_COLOR = BLUE
const WORK_PAUSED_STATUS_COLOR = PURPLE
const WORK_COMPLETE_STATUS_COLOR = LIGHT_GRAY
const UPDATE_STATUS_COLOR = GREEN
const RETURN_TO_WORK_STATUS_COLOR = RED

export const IN_WORK = { text: IN_WORK_STATUS_TEXT, color: IN_WORK_STATUS_COLOR }
export const WORK_PAUSED = { text: WORK_PAUSED_STATUS_TEXT, color: WORK_PAUSED_STATUS_COLOR }
export const WORK_COMPLETE = { text: WORK_COMPLETE_STATUS_TEXT, color: WORK_COMPLETE_STATUS_COLOR }
export const UPDATE = { text: UPDATE_STATUS_TEXT, color: UPDATE_STATUS_COLOR }
export const RETURN_TO_WORK = { text: RETURN_TO_WORK_STATUS_TEXT, color: RETURN_TO_WORK_STATUS_COLOR }

export const WORKFLOW_STATUSES_GROUP = {
  [IN_WORK_STATUS_TEXT]: IN_WORK,
  [WORK_PAUSED_STATUS_TEXT]: WORK_PAUSED,
  [WORK_COMPLETE_STATUS_TEXT]: WORK_COMPLETE,
  [UPDATE_STATUS_TEXT]: UPDATE,
  [RETURN_TO_WORK_STATUS_TEXT]: RETURN_TO_WORK
}

export const WORKFLOW_STATUSES_GROUP_BY_ID = {
  1: IN_WORK,
  2: WORK_PAUSED,
  3: UPDATE,
  4: RETURN_TO_WORK,
  5: WORK_COMPLETE
}
