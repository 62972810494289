<template>
  <match-media v-slot="{ desktop }">
    <v-table-row class="customer-item" @click="() => desktop && edit()">
      <div v-if="hasPending" class="customer-item__overlay-wrapper" @click="overlayClick">
        <p class="customer-item__overlay-text">Отправлен запрос на изменения. <span>Посмотреть</span></p>
      </div>
      <div class="customer-item__content">
        <v-tag v-if="workflowStatus" class="customer-item__documents-status" :custom-color="workflowStatus.color">
          {{ workflowStatus.shortname || workflowStatus.name }}
        </v-tag>
        <div class="customer-item__cell customer-item__cell--date">{{ formattedDate }}</div>

        <v-button
          class="customer-item__cell customer-item__cell--customer-name"
          is-text
          :to="{ name: 'customer-edit', params: { customerId: id } }"
          >{{ name }}</v-button
        >
        <router-link :to="agentProfileLink" @click.stop>
          <v-avatar
            class="customer-item__cell customer-item__cell--agent-avatar"
            size="small"
            :src="agentObject.photo"
          />
        </router-link>
        <div class="customer-item__cell customer-item__cell--agent-name">
          <router-link class="customer-item__agent-link" :to="agentProfileLink" @click.stop>
            {{ agentObject.name }}
          </router-link>
        </div>
        <div class="customer-item__adverts" @click.stop>
          <v-adverts-popover :adverts="adverts">
            <div class="customer-item__cell customer-item__cell--adverts-title">{{ advertsTitle }}</div>
          </v-adverts-popover>
        </div>
        <div class="customer-item__cell customer-item__cell--phone">{{ phoneMask }}</div>
        <div class="customer-item__cell customer-item__cell--email">{{ email }}</div>
      </div>

      <div class="customer-item__actions" @click.stop>
        <template v-if="desktop">
          <v-button-icon v-if="isActive" @click="archive">
            <v-icon-archive />
          </v-button-icon>
          <v-button-icon v-if="isArchive" @click="activate">
            <v-icon-activate />
          </v-button-icon>
        </template>

        <v-popover-actions v-else class="customer-item__popover">
          <v-button-icon><v-icon-dots /> </v-button-icon>
          <template #actions="{ onClick }">
            <v-button-wide @click="onClick(open)">Открыть</v-button-wide>
            <v-button-wide @click="onClick(edit)">Редактировать</v-button-wide>
            <v-button-wide v-if="isRoleAdmin && isActive" @click="onClick(archive)">Архивировать</v-button-wide>
            <v-button-wide v-if="isRoleAdmin && isArchive" @click="onClick(activate)">Активировать</v-button-wide>
            <v-button-wide :href="`tel:${phone}`">Позвонить</v-button-wide>
            <v-button-wide :href="`https://wa.me/${phone.replace(/^8/, '+7')}`">Написать в WhatsApp</v-button-wide>
          </template>
        </v-popover-actions>
      </div>
    </v-table-row>
  </match-media>
</template>

<script>
import VAvatar from '@/components/common/VAvatar.vue'
import VIconArchive from '@/components/icons/VArchive.vue'
import VIconActivate from '@/components/icons/VActivate.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VTableRow from '@/components/common/VTableRow.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import { MatchMedia } from 'vue-component-media-queries'
import { formatDate, formatPhone } from '@/utils/formatters'
import { pluralizeNumeral } from '@/utils/pluralization'
import { CUSTOMER_STATUS_ACTIVE, CUSTOMER_STATUS_ARCHIVE } from '@/constants/statuses/customers'
import { WORKFLOW_STATUSES_GROUP_BY_ID } from '@/constants/workflowStatuses'
import VTag from '@/components/common/VTag.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VAdvertsPopover from '@/components/common/VAdvertsPopover.vue'
import VButton from '@/components/common/VButton.vue'

export default {
  WORKFLOW_STATUSES_GROUP_BY_ID,
  name: 'CustomerListItem',
  components: {
    VButton,
    VAdvertsPopover,
    VTag,
    VAvatar,
    VIconArchive,
    VIconActivate,
    VButtonIcon,
    VIconDots,
    VTableRow,
    VPopoverActions,
    VButtonWide,
    MatchMedia
  },
  inheritAttrs: false,
  props: {
    advertCallsCount: { type: Number, default: null },
    adverts: { type: Array, default: null },
    name: { type: String, required: true },
    phone: { type: [Number, String], required: true },
    email: { type: String, required: true },
    agent: { type: Object, default: null },
    status: { type: String, required: true },
    dateJoined: { type: String, required: true },
    hasPending: { type: Boolean, default: false },
    workflowStatus: { type: Number, default: null },
    id: { type: Number, required: true }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    agentObject() {
      return this.agent || {}
    },
    advertsTitle() {
      const count = this.advertCallsCount || 0
      const text = pluralizeNumeral(count, 'лид', 'лида', 'лидов')
      return `${count} ${text}`
    },
    agentProfileLink() {
      // TODO add agent id parameter
      return { name: 'users' }
    },
    phoneMask() {
      return formatPhone(this.phone)
    },
    formattedDate() {
      return formatDate(this.dateJoined, ' ', true)
    },
    isActive() {
      return this.status === CUSTOMER_STATUS_ACTIVE
    },
    isArchive() {
      return this.status === CUSTOMER_STATUS_ARCHIVE
    }
  },
  methods: {
    overlayClick() {
      this.$emit('pending-edit')
    },
    archive() {
      this.$emit('archive')
    },
    activate() {
      this.$emit('activate')
    },
    edit() {
      this.$emit('edit')
    },
    open() {
      this.$emit('open')
    }
  }
}
</script>
