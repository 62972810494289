var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var desktop = ref.desktop;
var tablet = ref.tablet;
var mobile = ref.mobile;
return [_c('aside',{staticClass:"customers__filters-mobile"},[_c('v-drawer',{attrs:{"visible":_vm.filtersVisible},on:{"update:visible":function($event){_vm.filtersVisible=$event}}},[_c('filters-mobile',{attrs:{"default-filters":_vm.queryParams,"count":_vm.params.count},on:{"close":function($event){_vm.filtersVisible = false},"apply-filters":_vm.applyFilters}})],1)],1),(!mobile)?_c('aside',{staticClass:"customers__bulk-edit"},[_c('v-drawer',{attrs:{"position":"right","without-background":"","visible":_vm.bulkEditVisible},on:{"update:visible":function($event){_vm.bulkEditVisible=$event}}},[_c('customer-bulk-edit-dialog',{attrs:{"customers":_vm.selectedCustomersEdit},on:{"close":function($event){_vm.bulkEditVisible = false},"archive":_vm.archiveCustomers,"activate":_vm.activateCustomers,"submitted":_vm.editCustomerSubmit}})],1)],1):_vm._e(),_c('div',{staticClass:"customers"},[_c('div',{ref:"customersTopWrapper",staticClass:"customers__top-wrapper"},[_c('div',{staticClass:"customers__header"},[_c('v-page-title',{staticClass:"customers__title"},[_vm._v(" Покупатели "),_c('span',{staticClass:"customers__count"},[_vm._v(_vm._s(_vm.params.count))])]),(desktop)?_c('v-suggest-search',{staticClass:"customers__searchrow",attrs:{"min-number-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS,"min-string-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_STRING,"query-string":_vm.queryParams.phone || _vm.queryParams.query,"get-suggestions-method":_vm.getSearchSuggestionsList},on:{"reset":_vm.resetSuggestions},scopedSlots:_vm._u([{key:"searchResultItem",fn:function(ref){
var item = ref.item;
return [_c('customer-suggest-search-item',{attrs:{"customer":item},on:{"click":function($event){return _vm.goToCustomerPage(item.id)}}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"customers__buttons"},[(!mobile)?_c('v-button',{staticClass:"customers__button customers__button--filters",on:{"click":_vm.toggleFilters}},[_c('v-icon-filters',{staticClass:"customers__button-icon customers__button-icon--filters"}),_vm._v(" Фильтры ")],1):_vm._e()],1)],1),_c('div',{staticClass:"customers__tabs-wrapper"},[_c('v-tabs',{staticClass:"customers__tabs",attrs:{"value":_vm.queryParams.status,"primary":mobile},on:{"input":_vm.changeStatus}},_vm._l((_vm.$options.STATUSES_MAP),function(customerStatus,index){return _c('v-tab',{key:index,attrs:{"name":customerStatus.value}},[_vm._v(" "+_vm._s(customerStatus.label)+" ")])}),1)],1),(mobile)?_c('v-button',{staticClass:"customers__button customers__button--filters",on:{"click":_vm.toggleFilters}},[_c('v-icon-filters',{staticClass:"customers__button-icon customers__button-icon--filters"}),_vm._v(" Фильтры ")],1):_vm._e(),(!desktop)?_c('v-suggest-search',{staticClass:"customers__search",attrs:{"min-number-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS,"min-string-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_STRING,"query-string":_vm.queryParams.phone || _vm.queryParams.query,"get-suggestions-method":_vm.getSearchSuggestionsList},on:{"reset":_vm.resetSuggestions},scopedSlots:_vm._u([{key:"searchResultItem",fn:function(ref){
var item = ref.item;
return [_c('customer-suggest-search-item',{attrs:{"customer":item},on:{"click":function($event){return _vm.goToCustomerPage(item.id)}}})]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"customers__content"},[(!mobile)?[_c('customers-table',{attrs:{"table-height":_vm.tableHeight,"infinite-loading":_vm.infiniteLoading,"list":_vm.list,"infinite-id":_vm.infiniteId},on:{"edit":_vm.editCustomer,"next-page-request":_vm.nextPageRequest,"change-sort":_vm.applyFilters,"bulk-edit":_vm.bulkEdit}})]:[_vm._l((_vm.list),function(customer){return _c('customer-list-item',_vm._b({key:customer.id,on:{"archive":function($event){return _vm.archiveCustomers([customer.id])},"activate":function($event){return _vm.activateCustomers([customer.id])},"pending-edit":function($event){return _vm.editCustomer(customer)},"open":function($event){return _vm.goToCustomerPage(customer.id)},"edit":function($event){return _vm.editCustomerMobile(customer)}}},'customer-list-item',customer,false))}),_c('infinite-loading',{attrs:{"force-use-infinite-wrapper":"","identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler},scopedSlots:_vm._u([{key:"no-more",fn:function(){return [_c('span')]},proxy:true},{key:"no-results",fn:function(){return [_c('span')]},proxy:true}],null,true)})]],2),_c('customer-edit-dialog',{attrs:{"visible":_vm.editDialogVisible,"customer-data":_vm.customerData},on:{"update:visible":function($event){_vm.editDialogVisible=$event},"submitted":_vm.editCustomerSubmit}}),_c('customer-edit-pending-dialog',{attrs:{"visible":_vm.editPendingDialogVisible,"draft-data":_vm.customerDraftData,"customer-data":_vm.customerData},on:{"update:visible":function($event){_vm.editPendingDialogVisible=$event},"submitted":_vm.editCustomerSubmit}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }